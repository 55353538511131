import { graphql, useStaticQuery } from 'gatsby';

import Img from 'gatsby-image';
import React from 'react';

const ImgPeopleHoldingBlankBanner = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: {
          eq: "attributions/redessociales/people-holding-blank-banner-background.jpg"
        }
      ) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return <Img fixed={data.placeholderImage.childImageSharp.fixed} />;
};

export default ImgPeopleHoldingBlankBanner;
