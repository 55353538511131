import './styles/shapyaFonts.css';
import './styles/shapyaColors.css';
import './styles/shapyaMaxWidth.css';

import { Box } from '@material-ui/core';
import ImgAppCommerceShopping from './imgAttrComponents/imgAppCommerceShopping';
import ImgBackgroundvector from './imgAttrComponents/imgBackgroundvector';
import ImgBeer from './imgAttrComponents/imgBeer';
import ImgBusinessPlanPana from './imgAttrComponents/imgBusinessPlanPana';
import ImgBusinessvector from './imgAttrComponents/imgBusinessvector';
import ImgBusinessvector2 from './imgAttrComponents/imgBusinessvector2';
import ImgBusinessvector3 from './imgAttrComponents/imgBusinessvector3';
import ImgCandiesBasketPana from './imgAttrComponents/imgCandiesBasketPana';
import ImgCandy from './imgAttrComponents/imgCandy';
import ImgClock from './imgAttrComponents/imgClock';
import ImgDonation from './imgAttrComponents/imgDonation';
import ImgDrug from './imgAttrComponents/imgDrug';
import ImgEntregado from './imgAttrComponents/imgEntregado';
import ImgFlowerBouquet from './imgAttrComponents/imgFlowerBouquet';
import ImgFood from './imgAttrComponents/imgFood';
import ImgFood1 from './imgAttrComponents/imgFood1';
import ImgFooddelivery from './imgAttrComponents/imgFooddelivery';
import ImgFooddelivery2 from './imgAttrComponents/imgFooddelivery2';
import ImgFooddelivery3 from './imgAttrComponents/imgFooddelivery3';
import ImgFoodtruck from './imgAttrComponents/imgFoodtruck';
import ImgFoodvector from './imgAttrComponents/imgFoodvector';
import ImgFoodvector2 from './imgAttrComponents/imgFoodvector2';
import ImgIsometricPeopleOnlineShopping from './imgAttrComponents/imgIsometricPeopleOnlineShopping';
import ImgLaunchingPana from './imgAttrComponents/imgLaunchingPana';
import ImgMarket from './imgAttrComponents/imgMarket';
import ImgMeat from './imgAttrComponents/imgMeat';
import ImgMedal from './imgAttrComponents/imgMedal';
import ImgNotification from './imgAttrComponents/imgNotification';
import ImgPassword from './imgAttrComponents/imgPassword';
import ImgPeopleHoldingBlankBanner from './imgAttrComponents/imgPeopleHoldingBlankBanner';
import ImgPeoplevector from './imgAttrComponents/imgPeoplevector';
import ImgPin from './imgAttrComponents/imgPin';
import ImgSms from './imgAttrComponents/imgSms';
import ImgStartupLifePana from './imgAttrComponents/imgStartupLifePana';
import ImgStreetMapPinRoutes from './imgAttrComponents/imgStreetMapPinRoutes';
import ImgSubscriberPana from './imgAttrComponents/imgSubscriberPana';
import ImgSupermarket from './imgAttrComponents/imgSupermarket';
import ImgUnitedStates from './imgAttrComponents/imgUnitedStates';
import ImgUnlock from './imgAttrComponents/imgUnlock';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(usage, image, made, source) {
  return { usage, image, made, source };
}

const rows = [
  createData(
    'Mobile App',
    <span>
      <ImgAppCommerceShopping />
      &nbsp;
      <ImgFood />
    </span>,
    'Icons made by Smashicons',
    <a
      href="https://www.flaticon.com/authors/smashicons"
      target="_blank"
      title="Flaticon Smashicons"
    >
      www.flaticon.com/authors/smashicons
    </a>,
  ),
  createData(
    'Mobile App',
    <span>
      <ImgMarket />
    </span>,
    'Icons made by Surang',
    <a
      href="https://www.flaticon.com/authors/surang"
      target="_blank"
      title="Flaticon Surang"
    >
      www.flaticon.com/authors/surang
    </a>,
  ),
  createData(
    'Mobile App',
    <span>
      <ImgSms />
    </span>,
    'Icons made by Itim2101',
    <a
      href="https://www.flaticon.com/authors/itim2101"
      target="_blank"
      title="Flaticon Itim2101"
    >
      www.flaticon.com/authors/itim2101
    </a>,
  ),
  createData(
    'Mobile App',
    <span>
      <ImgFood1 />
    </span>,
    'Icons made by Pause08',
    <a
      href="https://www.flaticon.com/authors/pause08"
      target="_blank"
      title="Flaticon Pause08"
    >
      www.flaticon.com/authors/pause08
    </a>,
  ),
  createData(
    'Mobile App',
    <span>
      <ImgEntregado />
    </span>,
    'Icons made by Roundicons',
    <a
      href="https://www.flaticon.com/authors/roundicons"
      target="_blank"
      title="Flaticon Roundicons"
    >
      www.flaticon.com/authors/roundicons
    </a>,
  ),
  createData(
    'Mobile App',
    <span>
      <ImgFoodtruck />
      &nbsp;
      <ImgMeat />
    </span>,
    'Icons made by Good Ware',
    <a
      href="https://www.flaticon.com/authors/good-ware"
      target="_blank"
      title="Flaticon Good Ware"
    >
      www.flaticon.com/authors/good-ware
    </a>,
  ),
  createData(
    'Mobile App',
    <span>
      <ImgFooddelivery2 />
      &nbsp;
      <ImgPassword />
      &nbsp;
      <ImgPin />
      &nbsp;
      <ImgSupermarket />
      &nbsp;
      <ImgCandy />
      &nbsp;
      <ImgDrug />
      &nbsp;
      <ImgBeer />
      &nbsp;
      <ImgDonation />
      &nbsp;
      <ImgFlowerBouquet />
      &nbsp;
      <ImgNotification />
    </span>,
    'Icons made by Freepik',
    <a
      href="https://www.flaticon.com/authors/freepik"
      target="_blank"
      title="Flaticon Freepik"
    >
      www.flaticon.com/authors/freepik
    </a>,
  ),
  createData(
    'Mobile App',
    <span>
      <ImgUnlock />
    </span>,
    'Icons made by Pixel perfect',
    <a
      href="https://www.flaticon.com/authors/pixel-perfect"
      target="_blank"
      title="Pixel perfect"
    >
      www.flaticon.com/authors/pixel-perfect
    </a>,
  ),
  createData(
    'Mobile App',
    <span>
      <ImgBusinessvector />
    </span>,
    'Icons made by Pikisuperstar',
    <a
      href="https://www.freepik.com/vectors/business"
      target="_blank"
      title="Freepik Pikisuperstar"
    >
      www.freepik.com/vectors/business
    </a>,
  ),
  createData(
    'Mobile App',
    <span>
      <ImgStreetMapPinRoutes />
    </span>,
    'Icons made by Freepik',
    <a
      href="https://www.freepik.com/vectors/map"
      target="_blank"
      title="Freepik"
    >
      www.freepik.com/vectors/map
    </a>,
  ),
  createData(
    'Website',
    <span>
      <ImgFoodvector />
    </span>,
    'Icons made by Stories',
    <a
      href="https://www.freepik.com/vectors/food"
      target="_blank"
      title="Freepik Stories"
    >
      www.freepik.com/vectors/food
    </a>,
  ),
  createData(
    'Website',
    <span>
      <ImgFooddelivery />
    </span>,
    'Icons made by Freepik',
    <a
      href="https://www.flaticon.com/authors/freepik"
      target="_blank"
      title="Flaticon Freepik"
    >
      www.flaticon.com/authors/freepik
    </a>,
  ),
  createData(
    'Website',
    <span>
      <ImgMedal />
    </span>,
    'Icons made by Vectors Market',
    <a
      href="https://www.flaticon.com/authors/vectors-market"
      target="_blank"
      title="Flaticon Vectors Market"
    >
      www.flaticon.com/authors/vectors-market
    </a>,
  ),
  createData(
    'Website',
    <span>
      <ImgBusinessvector2 />
    </span>,
    'Icons made by Stories',
    <a
      href="https://www.freepik.com/vectors/business"
      target="_blank"
      title="Freepik Stories"
    >
      www.freepik.com/vectors/business
    </a>,
  ),
  createData(
    'Website',
    <span>
      <ImgFooddelivery3 />
    </span>,
    'Icons made by Ultimatearm',
    <a
      href="https://www.flaticon.com/authors/ultimatearm"
      target="_blank"
      title="Flaticon Ultimatearm"
    >
      www.flaticon.com/authors/ultimatearm
    </a>,
  ),
  createData(
    'Website',
    <span>
      <ImgClock />
    </span>,
    'Icons made by Freepik',
    <a
      href="https://www.flaticon.com/authors/freepik"
      target="_blank"
      title="Flaticon Freepik"
    >
      www.flaticon.com/authors/freepik
    </a>,
  ),
  createData(
    'Website',
    <span>
      <ImgPeoplevector />
    </span>,
    'Icons made by Stories',
    <a
      href="https://www.freepik.com/vectors/people"
      target="_blank"
      title="Freepik Stories"
    >
      www.freepik.com/vectors/people
    </a>,
  ),
  createData(
    'Website',
    <span>
      <ImgBusinessPlanPana />
      &nbsp;
      <ImgStartupLifePana />
      &nbsp;
      <ImgLaunchingPana />
    </span>,
    'Illustration by Freepik Storyset',
    <a
      href="https://storyset.com/business"
      target="_blank"
      title="Freepik Storyset"
    >
      www.storyset.com/business
    </a>,
  ),
  createData(
    'Website',
    <span>
      <ImgSubscriberPana />
    </span>,
    'Illustration by Freepik Storyset',
    <a
      href="https://storyset.com/social-media"
      target="_blank"
      title="Freepik Storyset"
    >
      www.storyset.com/social-media
    </a>,
  ),
  createData(
    'Social Media',
    <span>
      <ImgBackgroundvector />
    </span>,
    'Icons made by Vectorpocket',
    <a
      href="https://www.freepik.com/vectors/background"
      target="_blank"
      title="Freepik Vectorpocket"
    >
      www.freepik.com/vectors/background
    </a>,
  ),
  createData(
    'Social Media',
    <span>
      <ImgBusinessvector3 />
    </span>,
    'Icons made by Stories',
    <a
      href="https://www.freepik.com/vectors/business"
      target="_blank"
      title="Freepik Stories"
    >
      www.freepik.com/vectors/business
    </a>,
  ),
  createData(
    'Social Media',
    <span>
      <ImgFoodvector2 />
      &nbsp;
      <ImgIsometricPeopleOnlineShopping />
    </span>,
    'Icons made by Pikisuperstar Macrovector',
    <a
      href="https://www.freepik.com/vectors/food"
      target="_blank"
      title="Freepik Pikisuperstar"
    >
      www.freepik.com/vectors/food
    </a>,
  ),
  createData(
    'Social Media',
    <span>
      <ImgCandiesBasketPana />
    </span>,
    'Illustration by Freepik Stories',
    <a
      href="https://stories.freepik.com/people"
      target="_blank"
      title="Freepik Stories"
    >
      stories.freepik.com/people
    </a>,
  ),
  createData(
    'Social Media',
    <span>
      <ImgPeopleHoldingBlankBanner />
    </span>,
    'Background vector created by freepik',
    <a
      href="https://www.freepik.com/vectors/background"
      target="_blank"
      title="Freepik"
    >
      www.freepik.com/vectors/background
    </a>,
  ),
  createData(
    'Social Media',
    <span>
      <ImgUnitedStates />
    </span>,
    'Icons made by freepik',
    <a
      href="https://www.flaticon.com/authors/freepik"
      target="_blank"
      title="Freepik"
    >
      www.flaticon.com/authors/freepik
    </a>,
  ),
];

const AuthorAttributionsBody = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className="shapyaMaxWidth">
      <Box
        m={5}
        fontSize={26}
        className="shapyaTitle"
        fontWeight="fontWeightBold"
      >
        {t('authorattr.title')}
      </Box>
      <Box m={2} mb={10}>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="author attributions"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Box
                    fontWeight="fontWeightBold"
                    ml={1}
                    className="shapyaMediumRed"
                  >
                    {t('authorattr.usage')}
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Box
                    fontWeight="fontWeightBold"
                    ml={1}
                    className="shapyaMediumRed"
                  >
                    {t('authorattr.image')}
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Box
                    fontWeight="fontWeightBold"
                    ml={1}
                    className="shapyaMediumRed"
                  >
                    {t('authorattr.madeby')}
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Box
                    fontWeight="fontWeightBold"
                    ml={1}
                    className="shapyaMediumRed"
                  >
                    {t('authorattr.source')}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.name}>
                  <TableCell align="left">
                    <Box pl={1}>{row.usage}</Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box pl={1}>{row.image}</Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box pl={1}>{row.made}</Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box pl={1}>{row.source}</Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default AuthorAttributionsBody;
